import React, { useState, useContext, useRef } from 'react'
import styled from 'styled-components'
import { DictionaryContext, DictionaryAccountContext } from '../../contexts'
import {
  useCustomer,
  useUpdateCustomerAddress
} from '../../hooks/account'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  border,
  headerHeight,
  underline,
  zIndex,
  underlineMultiLines
} from '../../styles'
import { quart } from '../../styles/eases'

export const EditAddressForm = ({ openForm = true, token, addressId }) => {
  const dictionaryAccount = useContext(DictionaryAccountContext)
  // const [newAddress, setNewAddress] = useState({
  //   address: {
  //     address1: ,
  //     address2: ,
  //     city: ,
  //     company: ,
  //     first_name: ,
  //     last_name: ,
  //     phone: ,
  //     province: ,
  //     country: ,
  //     zip: ,
  //     name: ,
  //     province_code: ,
  //     country_code: ,
  //     country_name:
  //   }
  // })

  const [{ updateAddress }] = useUpdateCustomerAddress()

  const handleSubmit = async (e, addressId) => {
    e.preventDefault()
    // await updateAddress(token, addressId, newAddress)
  }

  return (

    <>
      {openForm && (
        <StyledForm>
          <Heading>
            <Title><span>{dictionaryAccount.myAccount}</span></Title>
          </Heading>
          <StyledEditAddressForm
            onSubmit={e => handleSubmit(e)}
            // loading={loading}
          >

            <StyledInput
              name='firstName'
              type='text'
              // onChange={e => setFirstName(e.target.value)}
              placeholder={dictionaryAccount.firstName}
            />

            <StyledInput
              name='lastName'
              type='text'
              // onChange={e => setLastName(e.target.value)}
              placeholder={dictionaryAccount.lastName}
            />

            <StyledInput
              name='company'
              type='text'
              // onChange={e => setFirstName(e.target.value)}
              placeholder='company'
              className='field--full-line'
            />

            <StyledInput
              name='address1'
              type='text'
              // onChange={e => setFirstName(e.target.value)}
              placeholder='address'
            />

            <StyledInput
              name='address2'
              type='text'
              // onChange={e => setFirstName(e.target.value)}
              placeholder='address details'
            />

            <StyledInput
              name='city'
              type='text'
              // onChange={e => setFirstName(e.target.value)}
              placeholder='city'
            />

            <StyledInput
              name='zip'
              type='text'
              // onChange={e => setEmail(e.target.value)}
              placeholder='zip'
            />

            <StyledSubmit type='submit'>
              {dictionaryAccount.submit}
            </StyledSubmit>

            {/* <AcceptTerms email={email} className='form-accept-terms'>
            <input
              type='radio'
              id='privacy'
              value='privacy'
              checked={privacy}
              onClick={() => setPrivacy(!privacy)}
              readOnly
            />
            <label htmlFor='privacy' aria-label='privacy'>
              <SiteLink slug={privacyLinkForms.link[0].slug}>{dictionaryForm.acceptPrivacy}</SiteLink>
            </label>
          </AcceptTerms> */}

            {/* <Error error={error}>
            {error}
          </Error>

          <Success success={response}>
            {dictionaryAccount.successRegister}
          </Success> */}

            {/* <Loading loading={loading} className='register-loader' /> */}

          </StyledEditAddressForm>
        </StyledForm>
      )}
    </>
  )
}

const StyledForm = styled.div`
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${color.yellow};
  margin-bottom: ${headerHeight.mobile};
  z-index: ${zIndex.medium};

  @media (min-width: ${desktopBreakpoint}) { 
    width: ${desktopVW(840)};
    top: ${desktopVW(473)};
    position: absolute;
    border: ${border.medium};
    justify-content: center;
    margin-bottom: 0;
  }
`

const Heading = styled.div`
  padding: ${mobileVW(30)} ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(30)} ${desktopVW(60)};
  }
`

const Title = styled.h2`
 span {
  font-size: ${mobileVW(29)};
  line-height: ${mobileVW(44)};
  text-transform: capitalize;
  display: inline-block;
  text-decoration: underline;
 }
  

  @media (min-width: ${desktopBreakpoint}) { 
    span {
      font-size: ${desktopVW(26)};
      line-height: ${desktopVW(31)};
      font-weight: normal;
     }
  }
`

const StyledEditAddressForm = styled.form.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop)
})`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${border.medium};
  padding: ${mobileVW(30)} ${mobileVW(32)};
  visibility: ${({ loading }) => loading ? 'hidden' : 'inherit'};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(40)} ${desktopVW(60)};
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const StyledInput = styled.input`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  font-weight: bold;
  border: none;
  border-bottom: ${border.medium};
  background-color: transparent;
  width: 100%;
  margin-bottom: ${mobileVW(20)};

  &::placeholder {
    color: ${color.black};
  }

  &:focus {
    outline: none;
  }


  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    margin-bottom: ${desktopVW(33)};
    width: calc(50% - ${desktopVW(32)});
    padding-left: 0;
    margin-right: ${desktopVW(16)};

    &.field--full-line {
      width: calc(100% - ${desktopVW(48)});
    }
  }
`

const StyledSubmit = styled.button`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  height: ${mobileVW(65)};
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${border.medium};
  bottom: 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(20)};
    height: ${desktopVW(65)};
  }
`

const Error = styled.div`
  visibility: ${({ error }) => error ? 'inherit' : 'hidden'};
  color: ${color.red};
  padding: ${mobileVW(20)} 0 ${mobileVW(10)};
  display: inline-block;
  text-transform: initial;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(20)} 0 ${desktopVW(10)};
  }
`

const Success = styled.div`
  visibility: ${({ success }) => success ? 'inherit' : 'hidden'};
  height: ${({ success }) => success ? 'auto' : '0'};
  background: ${color.yellow};
  line-height: ${mobileVW(19)};
  display: inline-block;
  text-transform: initial;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVW(21)};
  }
`

const AcceptTerms = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${mobileVW(16)};
  width: 100%;
  top: 100%;

  label {
    font-size: ${mobileVW(10)};
    line-height: ${mobileVW(12)};
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: ${mobileVW(20)};
    cursor: pointer;
    line-height: ${mobileVW(12)};
    display: inline-block;
    color: ${color.black};
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 1px solid ${color.black};
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 3px;
    height: 3px;
    background: ${color.black};
    position: absolute;
    top: 5.3px;
    left: 5.3px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  @media (min-width: ${desktopBreakpoint}) {
    left: 0;
    width: 100%;
    padding-top: ${desktopVW(20)};

    label {
      font-size: ${desktopVW(10)};
      line-height: ${desktopVW(8)};
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      padding-left: ${desktopVW(20)};
      line-height: ${desktopVW(14)};
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
    }
  }
`
