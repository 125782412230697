import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { SiteLink } from '../SiteLink'
import { BackgroundImage } from './BackgroundImage'
import Loading from '../Loading'
import { getExistingUser } from '../../utils/localStorage'
import { DictionaryAccountContext } from '../../contexts'
import { useLoginCustomer, useLoggedIn } from '../../hooks/account'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  border,
  underline,
  headerHeight
} from '../../styles'

export const Login = () => {
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const [loginCustomer, { response, loading, error }] = useLoginCustomer()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    await loginCustomer(email, password)
  }

  /** Redirect if either login successful or user already logged in */
  const redirect = getExistingUser() || response
  useLoggedIn(redirect)

  return (
    <Wrapper>
      <BackgroundImage full />
      <StyledForm>
        <Heading>
          <Title><span>{dictionaryAccount.myAccount}</span></Title>
          <FormSelection>
            <Current>{dictionaryAccount.logIn}</Current>
            <RegisterForm slug='account/register' disableTransition>{dictionaryAccount.register} </RegisterForm>
          </FormSelection>
        </Heading>

        <StyledLogin onSubmit={e => handleSubmit(e)} loading={loading}>
          <StyledInput
            name='email'
            onChange={e => setEmail(e.target.value)}
            placeholder='Email'
          />
          <StyledInput
            name='password'
            type='password'
            onChange={e => setPassword(e.target.value)}
            placeholder={dictionaryAccount.password}
          />
          <StyledSubmit type='submit'>
            {dictionaryAccount.logIn}
          </StyledSubmit>

          <Error error={error}>
            {error}
          </Error>

          <ForgottenPassword slug='/account/forgot-password' disableTransition>
            <span>{dictionaryAccount.forgotPassword}</span>
          </ForgottenPassword>

          <Loading loading={loading} className='login-loader' />

        </StyledLogin>
      </StyledForm>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;

  .login-loader {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledForm = styled.div`
  width: 100%;
  position: relative;
  background: ${color.white};
  top: ${headerHeight.mobile};
  margin-bottom: ${headerHeight.mobile};

  @media (min-width: ${desktopBreakpoint}) { 
    width: ${desktopVW(420)};
    position: absolute;
    top: 50%;
    left: ${desktopVW(120)};
    transform: translateY(-50%);
    border: ${border.medium};
    justify-content: center;
    margin-bottom: 0;
  }
`

const Heading = styled.div`
  padding: ${mobileVW(30)} ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(30)} ${desktopVW(60)};
  }
`

const Title = styled.h2`
  span {
    font-size: ${mobileVW(29)};
    line-height: ${mobileVW(44)};
    text-transform: capitalize;
    display: inline-block;
    text-decoration: underline;
  }
  
  @media (min-width: ${desktopBreakpoint}) { 
    span {
      font-size: ${desktopVW(26)};
      line-height: ${desktopVW(31)};
      font-weight: normal;
     }
  }
`

const FormSelection = styled.div`
  display: flex;
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  font-weight: normal;
  padding-top: ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
    padding-top: ${desktopVW(32)};
  }
`

const Current = styled.h1`
  font-weight: normal;
  margin-right: ${mobileVW(16)};
  ${underline.static}

  @media (min-width: ${desktopBreakpoint}) { 
    margin-right: ${desktopVW(16)};
    font-weight: bold;

    ::after {
      visibility: hidden;
    }
  }
`

const RegisterForm = styled(SiteLink)`
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) { 
    color: ${color.darkGrey};
  }
`

const StyledLogin = styled.form.withConfig({
  shouldForwardProp: (prop) => !['loading'].includes(prop)
})`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${border.medium};
  padding: ${mobileVW(30)} ${mobileVW(32)};
  visibility: ${({ loading }) => loading ? 'hidden' : 'inherit'};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(40)} ${desktopVW(60)};
  }
`

const StyledInput = styled.input`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  font-weight: bold;
  border: none;
  border-bottom: ${border.medium};
  background-color: transparent;
  width: 100%;
  margin-bottom: ${mobileVW(20)};

  &::placeholder {
    color: ${color.black};
  }

  &:focus {
    outline: none;
  }

  &[name='password'] {
    text-transform: initial!important;

    &::placeholder {
      text-transform: uppercase!important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    margin-bottom: ${desktopVW(33)};
    padding-left: 0;
  }
`

const StyledSubmit = styled.button`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  height: ${mobileVW(65)};
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${border.medium};
  bottom: 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(20)};
    height: ${desktopVW(65)};
  }
`

const Error = styled.div`
  visibility: ${({ error }) => error ? 'inherit' : 'hidden'};
  color: ${color.red};
  padding: ${mobileVW(20)} 0 ${mobileVW(10)};
  display: inline-block;
  text-transform: initial;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(20)} 0 ${desktopVW(10)};
  }
`

const ForgottenPassword = styled(SiteLink)`
  width: 100%;
  padding: ${mobileVW(10)} ${mobileVW(32)};
  text-align: center;
  
  span {
    display: inline-block;
    text-transform: initial;
    font-weight: normal;
    ${underline.static}
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(10)} 0 ${desktopVW(30)};
  }
`
