import React, { useContext } from 'react'
import styled from 'styled-components'
import { formatDate } from '../../utils'
import { SiteLink } from '../SiteLink'
import {
  DictionaryContext,
  DictionaryAccountContext,
  DictionaryFormContext,
  StoreContext,
  LayoutContext
} from '../../contexts'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  border,
  color,
  underlineMultiLines
} from '../../styles'

export const Orders = ({ orders }) => {
  const { currency } = useContext(StoreContext)
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const dictionaryForm = useContext(DictionaryFormContext)
  const dictionary = useContext(DictionaryContext)
  const { node_locale } = useContext(LayoutContext)

  return (
    <>
      <Title>{dictionaryAccount.myOrders}</Title>
      <List>
        {orders && orders.edges.length >= 1 ? (
          orders.edges.map(({ node }, index) => (
            <Item key={index}>
              <a href={node.statusUrl + `&locale=${node_locale}`}>
                <Details>
                  <p><span>{dictionaryAccount.date}</span>{formatDate(node.processedAt.split('T')[0], true)}</p>
                  <p><span>{dictionaryForm.orderNumber}</span>{node.orderNumber}</p>
                  <p><span>{dictionaryAccount.total}</span>{node.totalPrice} {currency}</p>
                </Details>
                <List>
                  {node.lineItems.edges.map(({ node: item }) => (
                    <OrderItem key={item.title}>
                      <ImageWrapper>
                        <Image
                          src={item.variant.image.originalSrc}
                          alt='product image'
                        />
                      </ImageWrapper>
                      <ProductDetails>
                        <div><span>{item.title}</span> <span>{item.variant.price} {currency}</span></div>
                        <div><span className='product-quantity'>{item.quantity}</span></div>
                      </ProductDetails>
                    </OrderItem>
                  )
                  )}
                </List>

              </a>
              <ButtonsWrapper>

                {node.shippingAddress && (
                  <Track
                    slug={`order-tracking/?${node.shippingAddress.zip.replace(' ', '')}&${node.orderNumber}`}
                  >
                    <span>
                      {dictionaryAccount.trackOrder}
                    </span>
                  </Track>
                )}

                <Return
                  slug={`order-return/?${node.orderNumber}`}
                >
                  <span>
                    {dictionaryAccount.returnOrder}
                  </span>
                </Return>
              </ButtonsWrapper>
            </Item>
          ))
        ) : (
          <Item className='item-no-results'>{dictionary.noResults}</Item>
        )}
      </List>
    </>
  )
}

const Title = styled.h2`
  font-size: ${mobileVW(29)};
  line-height: ${mobileVW(44)};
  padding: 0 ${mobileVW(32)};
  text-transform: capitalize; 

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(45)};
    line-height: ${desktopVW(42)};
    padding: 0;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;

    a {
      width: 100%;
    }
  }
`

const Item = styled.li`
  position: relative;
  border: ${border.medium};
  margin: ${mobileVW(20)} 0;
  padding: ${mobileVW(25)};
  font-weight: normal;
  display: flex;
  flex-direction: column;

  &.item-no-results {
    border: none;
    padding: ${mobileVW(30)} ${mobileVW(32)};
  }

  a {
    font-size: ${mobileVW(12)};
    line-height: ${mobileVW(15)};
    padding-bottom: ${mobileVW(5)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVW(30)} ${desktopVW(30)} ${desktopVW(30)} 0;
    padding: ${desktopVW(60)};
    width: 100%;
    
    &.item-no-results {
      padding: ${desktopVW(30)} 0;
    }

    a {
      font-size: ${desktopVW(12)};
      line-height: ${desktopVW(15)};
      padding-bottom: ${desktopVW(5)};
    }
  }
`

const Details = styled.div`
  text-transform: capitalize;
  font-weight: normal;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  border-bottom: ${border.thin};
  padding-bottom: ${mobileVW(10)};

  p {
    text-transform: capitalize;
    font-size: ${mobileVW(14)};
    line-height: ${mobileVW(35)};

    span {
      text-transform: capitalize;
      font-weight: bold;
      font-size: ${mobileVW(20)};
      line-height: ${mobileVW(35)};
      padding-right: ${mobileVW(10)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(19)};
    padding-bottom: ${desktopVW(20)};
    justify-content: space-between;
    flex-direction: row;

    p {
      font-weight: normal;
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(26)};

      span {
        font-weight: normal;
        font-size: ${desktopVW(20)};
        line-height: ${desktopVW(35)};
        padding-right: ${desktopVW(10)};
      }
    }
  }
`

const OrderItem = styled.li`
  display: flex;
  align-items: center;
  padding-bottom: ${mobileVW(16)};
  margin-top: ${mobileVW(16)};
  border-bottom: ${border.thin};
  width: 100%;
  
  span {
    padding: 0 ${mobileVW(5)};
    text-transform: lowercase;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(5)} 0 ${desktopVW(20)};
    margin-top: ${desktopVW(20)};
    height: auto;

    span {
      padding: 0 ${desktopVW(5)};
    }
  }
`

const ImageWrapper = styled.div`
  flex: 0 0 auto;
  width: ${mobileVW(60)};
  height: ${mobileVW(76)};
  margin-right: ${mobileVW(10)};
  background: ${color.grey};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(60)};
    height: ${desktopVW(76)};
    margin-right: ${desktopVW(20)};
  }
`

const Image = styled.img`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: ${mobileVW(15)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(15)} 0;
  }
`

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${mobileVW(76)};
  width: 100%;
  font-weight: bold;

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      text-transform: initial;

      &.product-quantity {
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: ${mobileVW(34)};
        width: ${mobileVW(34)};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(76)};

    div {
      span {
        &.product-quantity {
          height: ${desktopVW(34)};
          width: ${desktopVW(34)};
        }
      }
    }
  }
`

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: flex-end;
  }
`

const Track = styled(SiteLink)`
  position: relative;
  width: 100%;  
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(20)};
  margin-top: ${mobileVW(20)};
  text-align: center;

  span {
    ${underlineMultiLines}
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(20)};
    margin-top: ${desktopVW(20)};
    text-align: right;
  }
`

const Return = styled(Track)``
