import React, { useContext } from 'react'
import { LayoutContext } from '../../contexts'
import { useCustomer, useNotLoggedIn } from '../../hooks/account'
import { getPathname } from '../../utils/index'

const AuthWrapper = (props) => {
  const { component: Component } = props
  const user = useCustomer()

  useNotLoggedIn(!user)

  return (
    <>
      <Component />
    </>
  )
}

export default AuthWrapper
