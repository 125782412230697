import React, { useContext } from 'react'
import styled from 'styled-components'
import { SiteLink } from '../SiteLink'
import { BackgroundImage } from './BackgroundImage'
import { DictionaryAccountContext } from '../../contexts'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  border,
  headerHeight
} from '../../styles'

export const InvalidToken = () => {
  const dictionaryAccount = useContext(DictionaryAccountContext)

  return (
    <Wrapper>
      <BackgroundImage full />
      <StyledMessage slug='account/login' disableTransition>
        <Heading>
          <Title><span>{dictionaryAccount.tokenExpired}</span></Title>
        </Heading>

      </StyledMessage>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  
  .forgot-loader {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledMessage = styled(SiteLink)`
  width: 100%;
  position: relative;
  background: ${color.white};
  top: ${headerHeight.mobile};
  margin-bottom: ${headerHeight.mobile};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) { 
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: ${border.medium};
    justify-content: center;
    margin-bottom: 0;
    padding: ${desktopVW(60)};
  }
`

const Heading = styled.div`
  padding: ${mobileVW(30)} ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(30)} ${desktopVW(60)};
  }
`

const Title = styled.h2`
  span {
    font-size: ${mobileVW(29)};
    line-height: ${mobileVW(44)};
    text-transform: capitalize;
    display: inline-block;
    text-decoration: underline;
  }
  
  @media (min-width: ${desktopBreakpoint}) { 
    span {
      font-size: ${desktopVW(26)};
      line-height: ${desktopVW(31)};
      font-weight: normal;
     }
  }
`
