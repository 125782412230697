import React, { useContext, useEffect } from 'react'
import { Router, Location } from '@reach/router'
import AuthWrapper from '../components/account/AuthWrapper'
import { Dashboard } from '../components/account/Dashboard'
import { Login } from '../components/account/Login'
import { ForgotPassword } from '../components/account/ForgotPassword'
import { Register } from '../components/account/Register'
import { Reset } from '../components/account/Reset'
import { Activate } from '../components/account/Activate'
import { InvalidToken } from '../components/account/InvalidToken'
import SEO from '../components/SEO'
import { DictionaryAccountContext, LayoutContext } from '../contexts'
import { getPathname } from '../utils/index'

const Account = () => {
  const { node_locale } = useContext(LayoutContext)
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const trimmedSlug = (slug) => getPathname(node_locale, slug).replace(/^\/|\/$/g, '')

  const seoTitleSwitch = (location) => {
    switch (location.pathname.replace(/^\/|\/$/g, '')) {
      case trimmedSlug('account'):
        return dictionaryAccount.seoTitleDashboard
      case trimmedSlug('account/login'):
        return dictionaryAccount.seoTitleLogin
      case trimmedSlug('account/register'):
        return dictionaryAccount.seoTitleRegister
      case trimmedSlug('account/forgot-password'):
        return dictionaryAccount.seoTitleResetPassword
      case trimmedSlug('account/invalid_token'):
        return dictionaryAccount.seoTitleForgotPassword
      default:
        return null
    }
  }

  const renderSwitch = (location) => {
    switch (location.pathname.replace(/^\/|\/$/g, '')) {
      case trimmedSlug('account'):
        return <AuthWrapper component={Dashboard} />
      case trimmedSlug('account/login'):
        return <Login />
      case trimmedSlug('account/register'):
        return <Register />
      case trimmedSlug('account/forgot-password'):
        return <ForgotPassword />
      case trimmedSlug('account/invalid_token'):
        return <InvalidToken />
      default:
        return null
    }
  }

  return (
    <>
      {/* Routes managed by gatsby.node only */}
      <Location>
        {({ location }) => (
          <>
            <SEO title={seoTitleSwitch(location)} />
            <section>
              {renderSwitch(location)}
            </section>
          </>
        )}
      </Location>

      {/* Routes managed by gatsby.node and gatsby-plugin-create-client-paths */}
      <Router>
        <Reset path={getPathname(node_locale, 'account/reset/:id/:token')} />
        <Activate path={getPathname(node_locale, 'account/activate/:id/:token')} />
      </Router>
    </>
  )
}

export default Account
