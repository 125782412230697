import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { SiteLink } from '../SiteLink'
import Loading from '../Loading'
import { BackgroundImage } from './BackgroundImage'
import { DictionaryAccountContext, DictionaryFormContext, LayoutContext } from '../../contexts'
import { useRegisterCustomer } from '../../hooks/account'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  headerHeight,
  border,
  underline,
  underlineMultiLines
} from '../../styles'

export const Register = () => {
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const { privacyLinkForms } = useContext(LayoutContext)
  const dictionaryForm = useContext(DictionaryFormContext)
  const [registerCustomer, { response, loading, error }] = useRegisterCustomer()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [passwordField1, setPasswordField1] = useState('')
  const [passwordField2, setPasswordField2] = useState('')
  const [privacy, setPrivacy] = useState(false)

  const form = React.createRef()

  const handleSubmit = async (e) => {
    e.preventDefault()

    await registerCustomer(
      email,
      passwordField1,
      passwordField2,
      firstName,
      lastName,
      privacy
    )
  }

  return (
    <Wrapper>
      <BackgroundImage full />
      <StyledForm>
        <Heading>
          <Title><span>{dictionaryAccount.myAccount}</span></Title>
          <FormSelection>
            <LoginForm slug='account/login' disableTransition>{dictionaryAccount.logIn}</LoginForm>
            <Current>{dictionaryAccount.register}</Current>
          </FormSelection>
        </Heading>
        <StyledRegister
          ref={form}
          onSubmit={e => handleSubmit(e)}
          loading={loading}
        >

          <StyledInput
            name='firstName'
            type='text'
            onChange={e => setFirstName(e.target.value)}
            placeholder={dictionaryAccount.firstName}
          />

          <StyledInput
            name='lastName'
            type='text'
            onChange={e => setLastName(e.target.value)}
            placeholder={dictionaryAccount.lastName}
          />

          <StyledInput
            name='email'
            type='text'
            onChange={e => setEmail(e.target.value)}
            placeholder='Email'
          />

          <StyledInput
            name='password'
            value={passwordField1}
            onChange={e => setPasswordField1(e.target.value)}
            type='password'
            placeholder={dictionaryAccount.password}
          />
          <PasswordRequirements>
            {dictionaryAccount.passwordRequirements}
          </PasswordRequirements>

          <StyledInput
            name='passwordConfirm'
            value={passwordField2}
            onChange={e => setPasswordField2(e.target.value)}
            type='password'
            placeholder={dictionaryAccount.passwordConfirm}
          />

          <StyledSubmit type='submit'>
            {dictionaryAccount.submit}
          </StyledSubmit>

          <AcceptTerms email={email} className='form-accept-terms'>
            <input
              type='radio'
              id='privacy'
              value='privacy'
              checked={privacy}
              onClick={() => setPrivacy(!privacy)}
              readOnly
            />
            <label htmlFor='privacy' aria-label='privacy'>
              <StyledLink slug={privacyLinkForms.link[0].slug}>{dictionaryForm.acceptPrivacy}</StyledLink>
            </label>
          </AcceptTerms>

          <Error error={error}>
            {error && error.status === 422 ? (
              <StyledLink slug='account/forgot-password' error>{dictionaryAccount.forgotPassword}</StyledLink>
            ) : error}
          </Error>

          <Success success={response}>
            {dictionaryAccount.successRegister}
          </Success>

          <Loading loading={loading} className='register-loader' />

        </StyledRegister>
      </StyledForm>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;

  .register-loader {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledForm = styled.div`
  width: 100%;
  position: relative;
  background: ${color.white};
  top: ${headerHeight.mobile};
  margin-bottom: ${headerHeight.mobile};

  @media (min-width: ${desktopBreakpoint}) { 
    width: ${desktopVW(420)};
    position: absolute;
    top: 50%;
    left: ${desktopVW(120)};
    transform: translateY(-50%);
    border: ${border.medium};
    justify-content: center;
    margin-bottom: 0;
  }
`

const Heading = styled.div`
  padding: ${mobileVW(30)} ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(30)} ${desktopVW(60)};
  }
`

const Title = styled.h2`
 span {
  font-size: ${mobileVW(29)};
  line-height: ${mobileVW(44)};
  text-transform: capitalize;
  display: inline-block;
  text-decoration: underline;
 }
  

  @media (min-width: ${desktopBreakpoint}) { 
    span {
      font-size: ${desktopVW(26)};
      line-height: ${desktopVW(31)};
      font-weight: normal;
     }
  }
`

const FormSelection = styled.div`
  display: flex;
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  font-weight: normal;
  padding-top: ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
    padding-top: ${desktopVW(32)};
  }
`

const Current = styled.h1`
  font-weight: normal;
  ${underline.static}

  @media (min-width: ${desktopBreakpoint}) { 
    margin-right: ${desktopVW(16)};
    font-weight: bold;

    ::after {
      visibility: hidden;
    }
  }
`

const LoginForm = styled(SiteLink)`
  font-weight: normal;
  margin-right: ${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) { 
    margin-right: ${desktopVW(16)};
    color: ${color.darkGrey};
  }
`

const StyledRegister = styled.form.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop)
})`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${border.medium};
  padding: ${mobileVW(30)} ${mobileVW(32)};
  visibility: ${({ loading }) => loading ? 'hidden' : 'inherit'};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(40)} ${desktopVW(60)};
  }
`

const StyledInput = styled.input`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  font-weight: bold;
  border: none;
  border-bottom: ${border.medium};
  background-color: transparent;
  width: 100%;
  margin-bottom: ${mobileVW(20)};

  &::placeholder {
    color: ${color.black};
  }

  &:focus {
    outline: none;
  }

  &[name='password'] {
    text-transform: initial!important;

    &::placeholder {
      text-transform: uppercase!important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    margin-bottom: ${desktopVW(33)};
    padding-left: 0;

    &[name="password"] {
      margin-bottom: ${desktopVW(16)};
    }
  }
`

const PasswordRequirements = styled.p`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(12)};
  text-transform: initial;
  margin-bottom: ${mobileVW(16)};
  color: ${color.darkGrey};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(12)};
    margin-bottom: ${desktopVW(33)};
  }
`

const StyledSubmit = styled.button`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  height: ${mobileVW(65)};
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${border.medium};
  bottom: 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(20)};
    height: ${desktopVW(65)};
  }
`

const Error = styled.div`
  visibility: ${({ error }) => error ? 'inherit' : 'hidden'};
  color: ${color.red};
  padding: ${mobileVW(20)} 0 ${mobileVW(10)};
  display: inline-block;
  text-transform: initial;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(20)} 0 ${desktopVW(10)};
  }
`

const Success = styled.div`
  visibility: ${({ success }) => success ? 'inherit' : 'hidden'};
  height: ${({ success }) => success ? 'auto' : '0'};
  background: ${color.yellow};
  line-height: ${mobileVW(19)};
  display: inline-block;
  text-transform: initial;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVW(21)};
  }
`

const AcceptTerms = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${mobileVW(16)};
  width: 100%;
  top: 100%;

  label {
    font-size: ${mobileVW(10)};
    line-height: ${mobileVW(12)};
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: ${mobileVW(20)};
    cursor: pointer;
    line-height: ${mobileVW(12)};
    display: inline-block;
    color: ${color.black};
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 1px solid ${color.black};
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 3px;
    height: 3px;
    background: ${color.black};
    position: absolute;
    top: 5.3px;
    left: 5.3px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  @media (min-width: ${desktopBreakpoint}) {
    left: 0;
    width: 100%;
    padding-top: ${desktopVW(20)};

    label {
      font-size: ${desktopVW(10)};
      line-height: ${desktopVW(8)};
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      padding-left: ${desktopVW(20)};
      line-height: ${desktopVW(14)};
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
    }
  }
`

const StyledLink = styled(SiteLink)`
  ${underlineMultiLines}
  ${({ error }) => error
  ? `background-image: linear-gradient(${color.red}, ${color.red});`
  : null}
`
