import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import { EditAddressForm } from './EditAddressForm'
import { DictionaryContext, DictionaryAccountContext } from '../../contexts'
import {
  useCustomer,
  useUpdateCustomerAddress
} from '../../hooks/account'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  border,
  underlineMultiLines
} from '../../styles'
import { quart } from '../../styles/eases'

export const Addresses = ({ addresses, defaultAddress, token }) => {
  const dictionary = useContext(DictionaryContext)
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const addressRef = useRef()

  const user = useCustomer()
  const [{ updateAddress, deleteAddress }] = useUpdateCustomerAddress()

  const handleDeleteAddress = async (addressId) => {
    await deleteAddress(token, addressId)
    addressRef.current.style.transform = 'translate3d(0, -400%, 0)'
    setTimeout(() => (addressRef.current.style.display = 'none'), 700)
  }

  const handleEditAddress = async (addressId) => {
    await deleteAddress(token, addressId)
    addressRef.current.style.transform = 'translate3d(0, -400%, 0)'
    setTimeout(() => (addressRef.current.style.display = 'none'), 700)
  }

  return (
    <>
      <Title>{dictionaryAccount.myAddresses}</Title>
      <ListAddresses>
        {addresses && (
          addresses.edges.map(({ node }, index) => {
            return (
              <Item className='address-item' ref={addressRef} key={index}>
                <Row className='item-title'>{node.firstName} {node.lastName}</Row>
                <Row>{node.company ? node.company : null}</Row>
                <Row>{node.address1} {node.address2 ? node.address2 : null}</Row>
                <Row>{node.city} {node.zip} </Row>
                <Row>{node.country} </Row>
                <Row>{node.phone ? node.phone : null}</Row>
                <Row className='commands-row'>
                  {/* <Edit
                      aria-label='edit address'
                      onClick={() => handleEditAddress(node.id)}
                    >
                      <span>{dictionary.edit}</span>
                    </Edit> */}
                  <Delete
                    aria-label='delete address'
                    onClick={() => handleDeleteAddress(node.id)}
                  >
                    <span>{dictionary.delete}</span>
                  </Delete>
                </Row>
              </Item>
            )
          })
        )}

        {/* No addresses */}
        {(!user.addresses || !user.addresses.edges.length) && !user.defaultAddress && (
          <Item className='item-no-results'>{dictionary.noResults}</Item>
        )}

        {/* <EditAddressForm /> */}
      </ListAddresses>
    </>
  )
}

const Title = styled.h2`
  font-size: ${mobileVW(29)};
  line-height: ${mobileVW(44)};
  text-transform: capitalize; 

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(45)};
    line-height: ${desktopVW(42)};
  }
`

const ListAddresses = styled.ul`
display: flex;
flex-direction: column;
flex-wrap: wrap;
padding: 0;

@media (min-width: ${desktopBreakpoint}) {
  flex-direction: row;
}
`

const Item = styled.li`
  position: relative;
  border: ${border.medium};
  margin: ${mobileVW(20)} 0;
  padding: ${mobileVW(60)};
  font-weight: normal;
  display: flex;
  flex-direction: column;
  transition: transform .7s ${quart.inOut};

  &.item-no-results {
    border: none;
    padding: ${mobileVW(30)} 0;
  }

  a {
    font-size: ${mobileVW(12)};
    line-height: ${mobileVW(15)};
    padding-bottom: ${mobileVW(5)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVW(30)} ${desktopVW(30)} ${desktopVW(30)} 0;
    padding: ${desktopVW(60)};
    width: ${desktopVW(270)};
    overflow-y: auto;

    &.address-item {
      overflow-y: hidden;
    }
    
    &.item-no-results {
      padding: ${desktopVW(30)} 0;
    }

    a {
      font-size: ${desktopVW(12)};
      line-height: ${desktopVW(15)};
      padding-bottom: ${desktopVW(5)};
    }
  }
`
const Default = styled.p`
  position: absolute;
  background-color: ${color.yellow};
  color: ${color.black};
  display: inline-block;
  top: ${mobileVW(30)};
  right: ${mobileVW(30)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  padding: 0 ${mobileVW(4.5)};
  font-weight: bold;
  transform: rotate(-3.17deg);

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(30)};
    right: ${desktopVW(30)};
    transform: rotate(-3.17deg);
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
    padding: 0 ${desktopVW(4.5)};
  }
`

const Row = styled.div`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  text-transform: capitalize;
  padding-bottom: ${mobileVW(5)};

  &.item-title {
    font-weight: bold;
    font-size: ${mobileVW(24)};
    line-height: ${mobileVW(25)};
    padding-bottom: ${mobileVW(20)};
  }

  &.commands-row {
    display: flex;
    padding-top: ${mobileVW(20)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
    padding-bottom: ${desktopVW(5)};

    &.item-title {
      font-size: ${desktopVW(24)};
      line-height: ${desktopVW(25)};
      padding-bottom: ${desktopVW(20)};
    }

    &.commands-row {
      padding-top: ${desktopVW(20)};
    }
  }
`

const Edit = styled.button`
  padding-right: ${mobileVW(16)};

  span {
    ${underlineMultiLines}
    font-weight: bold;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding-right: ${desktopVW(20)};
  }
`

const Delete = styled(Edit)`
  color: ${color.red};

  span {
    background-image: linear-gradient(${color.red}, ${color.red});
  }
`
