import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Loading from '../Loading'
import { BackgroundImage } from './BackgroundImage'
import { DictionaryAccountContext } from '../../contexts'
import { useResetPasswordCustomer, useNotLoggedIn } from '../../hooks/account'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  border,
  headerHeight
} from '../../styles'

export const Reset = (props) => {
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const [
    resetPassword,
    { response, loading, error }
  ] = useResetPasswordCustomer()

  const [passwordField1, setPasswordField1] = useState('')
  const [passwordField2, setPasswordField2] = useState('')
  const form = React.createRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    await resetPassword(passwordField1, passwordField2, props.id, props.token)
  }

  useNotLoggedIn(response)

  return (
    <Wrapper>
      <BackgroundImage full />
      <StyledForm>
        <Heading>
          <Title><span>{dictionaryAccount.resetPassword}</span></Title>
        </Heading>
        <StyledResetForm
          onSubmit={e => handleSubmit(e)}
          loading={loading}
          ref={form}
        >
          <StyledInput
            name='password'
            type='password'
            value={passwordField1}
            onChange={e => setPasswordField1(e.target.value)}
            placeholder={dictionaryAccount.password}
          />

          <StyledInput
            name='passwordConfirm'
            type='password'
            value={passwordField2}
            onChange={e => setPasswordField2(e.target.value)}
            required
            placeholder={dictionaryAccount.passwordConfirm}
          />

          <StyledSubmit type='submit'>
            {dictionaryAccount.submit}
          </StyledSubmit>

          <Error error={error}>
            {error}
          </Error>

          <Loading loading={loading} className='reset-loader' />

        </StyledResetForm>
      </StyledForm>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;

  .reset-loader {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledForm = styled.div`
  width: 100%;
  position: relative;
  background: ${color.white};
  top: ${headerHeight.mobile};
  margin-bottom: ${headerHeight.mobile};

  @media (min-width: ${desktopBreakpoint}) { 
    width: ${desktopVW(420)};
    position: absolute;
    top: 50%;
    left: ${desktopVW(120)};
    transform: translateY(-50%);
    border: ${border.medium};
    justify-content: center;
    margin-bottom: 0;
  }
`

const Heading = styled.div`
  padding: ${mobileVW(30)} ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(30)} ${desktopVW(60)};
  }
`

const Title = styled.h2`
  span {
    font-size: ${mobileVW(29)};
    line-height: ${mobileVW(44)};
    text-transform: capitalize;
    display: inline-block;
    text-decoration: underline;
  }
  
  @media (min-width: ${desktopBreakpoint}) { 
    span {
      font-size: ${desktopVW(26)};
      line-height: ${desktopVW(31)};
      font-weight: normal;
     }
  }
`

const StyledResetForm = styled.form.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop)
})`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${border.medium};
  padding: ${mobileVW(30)} ${mobileVW(32)};
  visibility: ${({ loading }) => loading ? 'hidden' : 'inherit'};

  @media (min-width: ${desktopBreakpoint}) { 
    padding: ${desktopVW(40)} ${desktopVW(60)};
  }
`

const StyledInput = styled.input`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  font-weight: bold;
  border: none;
  border-bottom: ${border.medium};
  background-color: transparent;
  width: 100%;
  margin-bottom: ${mobileVW(20)};

  &::placeholder {
    color: ${color.black};
  }

  &:focus {
    outline: none;
  }

  &[name='password'] {
    text-transform: initial;

    &::placeholder {
      text-transform: uppercase;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    margin-bottom: ${desktopVW(33)};
    padding-left: 0;
  }
`

const StyledSubmit = styled.button`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  height: ${mobileVW(65)};
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${border.medium};
  bottom: 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(20)};
    height: ${desktopVW(65)};
  }
`

const Error = styled.div`
  visibility: ${({ error }) => error ? 'inherit' : 'hidden'};
  color: ${color.red};
  padding: ${mobileVW(20)} 0 ${mobileVW(10)};
  display: inline-block;
  text-transform: initial;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(20)} 0 ${desktopVW(10)};
  }
`
