import React, { useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import useHeaderColor from '../../hooks/useHeaderColor'
import useRootMargin from '../../hooks/window/useRootMargin'
import { DictionaryAccountContext } from '../../contexts'

import {
  desktopVW,
  color,
  desktopBreakpoint
} from '../../styles'

export const BackgroundImage = ({ full }) => {
  const dictionaryAccount = useContext(DictionaryAccountContext)
  const rootMargin = useRootMargin()
  const [refAccountBkgImg, inViewAccountBkgImg] = useInView({ rootMargin })

  useHeaderColor(
    inViewAccountBkgImg,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.yellow
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  return (
    <div ref={refAccountBkgImg}>
      <Background
        alt={dictionaryAccount.dashboardImage.title}
        fluid={dictionaryAccount.dashboardImage.fluid}
        backgroundColor={color.red}
        full={full}
      />
    </div>
  )
}

const Background = styled(Img)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) { 
    display: block;
    position: relative;
    height: ${({ full }) => full ? '100vh' : desktopVW(473)};
    width: 100%;
  }
`
