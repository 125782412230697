import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Loading from '../Loading'
import { BackgroundImage } from './BackgroundImage'
import { Orders } from './Orders'
import { Addresses } from './Addresses'
import { DictionaryAccountContext } from '../../contexts'
import {
  useCustomer,
  useLogoutCustomer,
  useUpdateCustomer,
  useUpdateCustomerOrders,
  useNotLoggedIn
} from '../../hooks/account'
import {
  mobileVW,
  desktopVW,
  color,
  desktopBreakpoint,
  border,
  headerHeight
} from '../../styles'

export const Dashboard = () => {
  const dictionaryAccount = useContext(DictionaryAccountContext)

  const [tab, setTab] = useState('orders')
  const user = useCustomer()
  const [logoutCustomer, { response }] = useLogoutCustomer()
  const [updateCustomer] = useUpdateCustomer()
  const [updateCustomerOrders] = useUpdateCustomerOrders()

  const handleLogout = async (e) => {
    e.preventDefault()
    await logoutCustomer(user)
  }

  useNotLoggedIn(response)

  useEffect(() => {
    if (user) {
      updateCustomer(user)
      updateCustomerOrders(user)
    }
  }, [])

  return (
    <Wrapper>
      <BackgroundImage />
      {user && (
        <StyledDashboard>
          <SideBar>
            <Greetings>
              {dictionaryAccount.hello}, {' '}
              {user.firstName ? user.firstName : <span>{user.email.split('@')[0]}</span>}
            </Greetings>
            <OrdersTab
              aria-label='orders'
              selected={tab === 'orders'}
              onClick={() => setTab('orders')}
            >{dictionaryAccount.myOrders}
            </OrdersTab>
            <AddressesTab
              aria-label='addresses'
              selected={tab === 'addresses'}
              onClick={() => setTab('addresses')}
            >{dictionaryAccount.myAddresses}
            </AddressesTab>
            <Logout onClick={e => handleLogout(e)}>
              {dictionaryAccount.logOut}
            </Logout>
          </SideBar>

          {/* Orders */}
          <Content show={tab === 'orders'} orders>
            <Orders orders={user.orders} />
          </Content>

          {/* Addresses */}
          <Content show={tab === 'addresses'}>
            <Addresses
              defaultAddress={user.defaultAddress}
              addresses={user.addresses}
              token={user.token}
            />
          </Content>
        </StyledDashboard>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

const StyledDashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
  }
`

const SideBar = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-bottom: ${border.medium};
  margin-top:${headerHeight.mobile};
  height: ${headerHeight.mobile};

  @media (min-width: ${desktopBreakpoint}) {
    height: 100%;
    width: ${desktopVW(360)};
    padding: ${desktopVW(60)};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    border: none;
  }
`

const Greetings = styled.h6`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    text-transform: capitalize; 
    font-size: ${desktopVW(45)};
    line-height: ${desktopVW(42)};
    padding-bottom: ${desktopVW(30)};

    span {
      text-transform: initial; 
      font-size: ${desktopVW(35)};
      line-height: ${desktopVW(32)};
    }
  }
`

const OrdersTab = styled.button`
  text-transform: initial;
  font-weight: normal;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(25)};
  height: ${mobileVW(25)};
  margin: 0 ${mobileVW(16)};
  text-decoration: ${({ selected }) => selected ? 'underline' : 'none'};

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(19)};
    height: auto;
    margin: 0;
  }
`

const AddressesTab = styled(OrdersTab)``

const Logout = styled(OrdersTab)`
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVW(30)};
  }
`

const Content = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  padding: ${({ orders }) => orders ? `${mobileVW(25)} 0 0` : `${mobileVW(25)} ${mobileVW(32)} 0`};
  overflow-y: scroll;
  
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(60)};
    width: calc(100vw - ${desktopVW(360)});
    flex-direction: column;
    border-left: ${border.medium};
    min-height: calc(100vh - ${desktopVW(473)});
  } 
`
