import React from 'react'
import styled from 'styled-components'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color
} from '../styles'

const Loading = ({ loading, className }) => {
  return (
    <StyledLoading visible={loading} className={className}>
      <div />
      <div />
      <div />
      <div />
    </StyledLoading>
  )
}

const StyledLoading = styled.div`
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  width: ${mobileVW(80)};
  height: ${mobileVW(80)};
  bottom:  -${mobileVW(80)};
  display: inline-block;
  position: relative;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${color.black};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${color.black} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(80)};
    height: ${desktopVW(80)};
    bottom: -${desktopVW(80)};
  }
`

export default Loading
